import { DialogType, TransitionType } from "..";

export default class BaseDialogCtrl {
  /**
   * @returns {string} - DialogTypeに列挙されている値
   */
  get type() {
    return this._type;
  }

  /**
   * @returns {string} - TransitionTypeに列挙されている値
   */
  get transition() {
    return this._transition;
  }

  /**
   * ダイアログの種類を表す。
   * @type {string} - DialogTypeに列挙されている値
   */
  _type = DialogType.Default;

  /**
   * アニメーションの種類を表す。
   * @type {string} - TransitionTypeに列挙されている値
   */
  _transition = TransitionType.Default;
}