/**
 * ダイアログの種類を列挙するEnum
 * @type {{string: string}}
 */
export const DialogType = {
  Default: "DEFAULT",
  MenuPanel: "MENU_PANEL",
  ChooseAlbum: "CHOOSE_ALBUM",
  CrystalPurchase: "CRYSTAL_PURCHASE",
  SelectPaymentMethod: "SELECT_PAYMENT_METHOD",
  EnterCreditCard: "ENTER_CREDIT_CARD",
  EnterNPPayment: "ENTER_NP_PAYMENT",
  Information: "INFORMATION",
  SimpleDialog2: "SIMPLE_DIALOG_2",
  MonthlyRanking: "MONTHLY_RANKING",
  NewLoginBonus: "NEW_LOGIN_BONUS",
  AccessPoint: "ACCESS_POINT",
  ReceivePresent: "RECEIVE_PRESENT",
};

/**
 * ダイアログアニメーションの種類を列挙するEnum
 * Enumの値はそのままv-dialogのAPIに渡される
 */
export const TransitionType = {
  Default: "dialog-transition",
  FromTop: "dialog-top-transition",
}

export default null; // defaultが無いと、ビルド時にWarningが出るため