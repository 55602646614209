import { DialogType } from "..";
import BaseDialogCtrl from "../base/controller";

/**
 * jqueryプラグインであるsimpledialog2と同じAPI（必要なものだけ）を提供するダイアログ
 */
export default class SimpleDialog2Ctrl extends BaseDialogCtrl {
  _type = DialogType.SimpleDialog2;

  /**
   * @type {string}
   */
  mode;
  
  /**
   * @type {string | string[] | undefined}
   */
  buttonPrompt;

  /**
   * @type {string | undefined}
   */
  buttonPromptHtml;
  
  /**
   * @type {[key: string]: {click: () => void}}
   */
  buttons;

  /**
   * @type {string}
   */
  headerText;

  /**
   * @type {boolean}
   */
  headerClose;

  /**
   * @type {?() => void}
   */
  callbackClose;

  /**
   * @type {?string}
   */
  blankContent;

  /**
   * @type {?number}
   */
  zindex;

  /**
   * @type {?Function}
   */
  onMounted;

  /**
   * @type {?string}
   */
  bodyTitle;

  /**
   * @type {?Boolean}
   */
   isHtml;

  /**
   * @param {{
   *   mode: string,
   *   buttonPrompt: string | string[] | undefined,
   *   buttonPromptHtml: string | undefined,
   *   buttons: {[key: string]: {click: () => void}},
   *   headerText: string,
   *   headerClose: boolean,
   *   callbackClose: (() => void) | null,
   *   blankContent: ?string,
   *   zindex: ?number,
   *   onMounted: ?Function,
   *   bodyTitle: ?string,
   *   isHtml: ?Boolean,
   * }} params
   */
  constructor(params) {
    super();

    this.mode          = params.mode;
    this.buttonPrompt  = params.buttonPrompt;
    this.buttonPromptHtml  = params.buttonPromptHtml;
    this.buttons       = params.buttons;
    this.headerText    = params.headerText;
    this.headerClose   = params.headerClose;
    this.callbackClose = params.callbackClose;
    this.blankContent  = params.blankContent;
    this.zindex        = params.zindex;
    this.onMounted     = params.onMounted;
    this.bodyTitle     = params.bodyTitle;
    this.isHtml        = params.isHtml;
  }
}